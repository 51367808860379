import React, { Component } from "react";
import { Link } from "react-router-dom";
class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggle: true,
    };
    this.clickHandler = this.clickHandler.bind(this);
  }

  clickHandler() {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));

    //  console.log(this)
  }

  render() {
    return (
      <div>
        <section className="relative">
          <header className=" absolute w-full z-10">
            <nav
              className="lg:max-w-screen-xl flex flex-row flex-wrap justify-between my-4 lg:my-6 ml-auto mr-auto "
              role="navigation"
            >
              <div className="flex items-center ml-3">
                <a href="/">
                  <img
                    className="logo"
                    src={require("./img/pixelarray1.png").default}
                    alt="Pixel array logo"
                  />
                </a>
              </div>
              <div className="inline-block my-4 h-8 mr-3 lg:hidden">
                <button
                  id="nav_toggle"
                  className="h-full  w-full"
                  onClick={this.clickHandler}
                >
                  <div></div>
                  <svg
                    className="h-full w-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    width="192"
                    height="192"
                    fill="#4B5563"
                    viewBox="0 0 256 256"
                  >
                    <rect width="256" height="256" fill="none"></rect>
                    <line
                      x1="40"
                      y1="128"
                      x2="216"
                      y2="128"
                      stroke="#4B5563"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="16"
                    ></line>
                    <line
                      x1="40"
                      y1="64"
                      x2="216"
                      y2="64"
                      stroke="#4B5563"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="16"
                    ></line>
                    <line
                      x1="40"
                      y1="192"
                      x2="216"
                      y2="192"
                      stroke="#4B5563"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="16"
                    ></line>
                  </svg>
                </button>
              </div>

              <div
                id="nav_content"
                className={
                  this.state.toggle
                    ? " w-full hidden py-8 lg:flex lg:w-auto lg:py-0 lg:items-center rounded-br-full shadow-md lg:shadow-none "
                    : " w-full backg py-8 lg:flex lg:w-auto lg:py-0 lg:items-center rounded-br-full shadow-md lg:shadow-none"
                }
              >
                <ul className="flex flex-col ml-auto text-md text-gray-700 font-semibold lg:flex-row">
                  <li className="mx-6 w-mc hover:text-white mb-4 lg:mb-0 ">
                    <Link to="/report"> Free Website Report</Link>
                  </li>
                  <li className="mx-6 w-mc hover:text-white mb-4 lg:mb-0 ">
                    <Link to="/contact"> Contact</Link>{" "}
                  </li>
                </ul>
              </div>
            </nav>
          </header>
        </section>
      </div>
    );
  }
}

export default Button;
