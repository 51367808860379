import React from 'react';
import { Link } from 'react-router-dom';

export const Growth = () => {
    return (
      <div>
        <section className="flex justify-center mx-4 md:w-2/3 md:mx-auto pt-24 lg:pt-30 pb-32 lg:pb-40">
          <div className="lg:flex items-center justify-center gap-x-14">
            <div className="lg:w-1/4 mb-16 lg:mb-0">
              <h2 className="inline-block text-3xl lg:text-4xl font-semibold uppercase text-white">
                Looking for a developer?
              </h2>
              <p className="leading-relaxed mt-10 mb-10 text-white ">
                I'm Darion - A creative software engineer with a deep interest in cloud engineering. Proven experience building consumer-facing websites with HTML5, MySQL/NoSQL, Javascript, and PHP. Love learning and helping people bring their product ideas to life using code. Let's connect today and build something special!
                <span> </span> 
              </p>

              <li className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300">
                <Link to="/contact">Send me a Message</Link>{" "}
              </li>
            </div>

            <div className="portimg ">
              {" "}
              <img
                className="rounded-sm"
                src={require("./img/prof.png").default}
                alt="me"
              />
            </div>
          </div>
        </section>

        <section className="py-24 shadow-inner bg-blue-50">
          <h2 className="text-center font-semibold text-xl lg:text-2xl mb-12 text-black">
            Ready to serve more customers?
          </h2>

          <div className="text-center">
            <li className="inline-block uppercase font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl text-sm lg:text-base rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300">
              <Link to="/contact"> CONTACT</Link>
            </li>
          </div>
        </section>
      </div>
    );
}

export default Growth