import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
//import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Contact from './components/layout/Contact';

import Report from './components/layout/Report';
import Footer from './components/layout/Footer';


//redux


import './App.css';
import './styles/index.css';

const App = () => (
  
  <Router>
  <Fragment>
    
        <Routes>

          <Route path="/" element={<Landing/>} />

          <Route path="/contact" element={<Contact/>}/>
          <Route path="/report" element={<Report/>}/>

       </Routes>
       
    <Footer />
    
  </Fragment>
</Router>

);

export default App;