import React from 'react';

export const Footer = () => {
    return (
      <footer className="py-16">
        <div className="flex flex-col lg:flex-row items-center justify-between mx-auto max-w-screen-xl">
          <div className="flex items-center">
            <a className="ml-4" href="/">
              <h1 className="text-5xl lg:text-7xl uppercase text-white font-medium mb-4 lg:mb-8">
                Pixel <span className="font-black">Array</span>
              </h1>
            </a>
          </div>
          <ul className="flex flex-col lg:flex-row items-center text-sm font-semibold text-gray-500 py-12 lg:py-0"></ul>
          <div className="flex text-white">
            <a
              className="hover:text-gray-700"
              href="https://twitter.com/pixel_array"
            >
              <i className="fab fa-twitter-square"></i>
            </a>

            <a className="hover:text-gray-700" href="https://github.com/">
              <i className="fab fa-git-square pl-5"></i>
            </a>

            <a className="hover:text-gray-700" href="https://linkedin.com/">
              <i className="fab fa-linkedin pl-5"></i>
            </a>
          </div>
        </div>

        <div className="flex flex-col items-center mt-24 text-xs text-white">
          <span>&copy; Pixel Array Web Solutions 2021</span>
        </div>
      </footer>
    );
}

export default Footer