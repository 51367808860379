import React from 'react'

export const Testimonials = () => {
    return (
      <div>
        <div className="flex flex-col shadow-inner rounded-r-10xl lg:rounded-r-full items-center pb-40 bg-blue-50">
          <section className="projects grid  pt-24">
            <div className="toolbox">
              <div className="tools">
                <h2 className="text-center text-3xl lg:text-4xl font-semibold uppercase text-black mb-20 lg:mb-24">
                  {" "}
                  Toolbox{" "}
                </h2>

                <ul>
                  <li className="react-img">
                    <p> REACT</p>
                  </li>
                  <li className="php-mysql">
                    <p> PHP </p>
                  </li>
                  <li className="html-card">
                    <p> HMTL 5 </p>
                  </li>
                  <li className="css-card">
                    <p> CSS </p>
                  </li>
                  <li className="node-js">
                    <p>Node.JS </p>
                  </li>
                  <li className="vanilla-card">
                    <p> Javascript</p>
                  </li>
                  <li className="git-card">
                    <p> Git</p>
                  </li>
                  <li className="twilio-card">
                    <p> Adobe Products</p>
                  </li>
                  <li className="vue-card">
                    <p> Vue</p>
                  </li>
                  <li className="graphql-card">
                    <p> Graph-QL</p>
                  </li>
                  <li className="heroku-card">
                    <p> Heroku Card</p>
                  </li>
                  <li className="mongodb-card">
                    <p> MongoDB</p>
                  </li>
                  <li className="gatsby-card">
                    <p> Gatsby</p>
                  </li>
                  <li className="aws-card">
                    <p> AWS</p>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
}

export default Testimonials
