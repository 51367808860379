import React from "react";
import Button from "./Button";

export const Contact = () => {
  return (
    <div>
      <Button />
      <section class=" pt-28">
        <div class="w-full lg:flex bg-white">
          <div class="relative flex-grow justify-center px-8 lg:px-20 flex items-center py-20 lg:py-48 rounded-r-full overflow-hidden">
            <div class="absolute top-0 right-0 bottom-0 left-0 bg-gray-800"></div>

            <div class="z-10">
              <h2 class="font-medium mb-5 text-yellow-50">Get in touch</h2>

              <div class="flex items-center mb-10">
                <div class="p-2 rounded-full mr-6 bg-gray-100">
                  <svg
                    class="text-gray-700 fill-current"
                    width="16"
                    height="12"
                    viewBox="0 0 16 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.8571 0H1.14286C0.839753 0 0.549062 0.120408 0.334735 0.334735C0.120408 0.549062 0 0.839753 0 1.14286V10.2857C0 10.5888 0.120408 10.8795 0.334735 11.0938C0.549062 11.3082 0.839753 11.4286 1.14286 11.4286H14.8571C15.1602 11.4286 15.4509 11.3082 15.6653 11.0938C15.8796 10.8795 16 10.5888 16 10.2857V1.14286C16 0.839753 15.8796 0.549062 15.6653 0.334735C15.4509 0.120408 15.1602 0 14.8571 0V0ZM13.6 1.14286L8 5.01714L2.4 1.14286H13.6ZM1.14286 10.2857V1.66286L7.67429 6.18286C7.76994 6.24922 7.88358 6.28478 8 6.28478C8.11642 6.28478 8.23006 6.24922 8.32571 6.18286L14.8571 1.66286V10.2857H1.14286Z" />
                  </svg>
                </div>
                <div>
                  <span class="block text-xs font-medium text-yellow-100">
                    Email Address
                  </span>
                  <span class="block font-medium text-yellow-50">
                    <a href="mailto:pixelarraymedia@gmail.com">
                      pixelarraymedia@gmail.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-grow justify-center bg-white px-8 lg:px-20 py-20 lg:py-48">
            <div class="flex justify-center">
              <div>
                <h2 class="text-xl lg:text-xl font-medium mb-1 text-gray-700">
                  Looking for a developer?
                </h2>
                <span class="text-sm mb-4 text-gray-400">
                  Pop me a message and let's connect.
                </span>

                <form
                  name="contact"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  method="POST"
                  autocomplete="off"
                >
                  <p class="hidden">
                    <label>
                      Don’t fill this out if you’re human:{" "}
                      <input name="bot-field" />
                    </label>
                  </p>
                  <input type="hidden" name="form-name" value="contact" />
                  <div class="input-animated">
                    <input
                      type="text"
                      id="name"
                      name="name"
                      required
                      pattern="\S+.*"
                      placeholder="the placeholder"
                    />
                    <label htmlFor="name" class="label-name">
                      <span class="content-name">Name</span>
                    </label>
                  </div>
                  <div class="input-animated">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      required
                      placeholder="the placeholder"
                    />
                    <label htmlFor="email" class="label-email">
                      <span class="content-email">Email</span>
                    </label>
                  </div>
                  <div>
                    <textarea
                      name="question"
                      placeholder="Your Question"
                      id="question"
                      cols="30"
                      rows="5"
                      minLength="10"
                      maxLength="100"
                      required
                    ></textarea>
                  </div>
                  <div data-netlify-recaptcha="true"></div>
                  <button
                    type="submit"
                    id="submit-button"
                    class="w-full font-semibold rounded-tl-3xl rounded-br-3xl uppercase text-center px-5 py-3 shadow-md my-4 lg:mb-0 bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-4 focus:ring-yellow-300"
                  >
                    Send Message
                  </button>
                  <span
                    id="success"
                    class="hidden absolute mt-2 px-4 py-2 font-medium border rounded-sm border-green-500 bg-green-100 text-green-600"
                  >
                    Thanks! We'll be in touch soon.
                  </span>
                  <span
                    id="error"
                    class="hidden absolute mt-2 px-4 py-2 font-medium border rounded-sm border-red-500 bg-red-100 text-red-600"
                  >
                    Whoops... Something went wrong.
                  </span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
