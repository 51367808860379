import React from 'react';

export const Services = () => {
    return (
      <div>
        <section className="py-16 lg:py-24 shadow-inner  bg-blue-50">
          <div className="lg:flex justify-center gap-x-18">
            <div className="mx-4 md:w-2/3 md:mx-auto mb-16 lg:mx-0 lg:mb-0 lg:w-80 flex flex-col items-center">
              <i className="fas fa-laptop-code text-gray-600"></i>
              <h2 className="text-xl lg:text-2xl mt-3 mb-6 font-semibold text-gray-900">
                Website Development
              </h2>
              {/* <p className="text-center text-gray-500"> I'm proficient with building Full Stack web applications from sratch using WAMP Windows Apache Mysql PHP and MERN MongoDB Express React Node.js. I can work with you to build fast Single Page applications or we can really dig into something more complex like an E-commerce website.
            </p> */}
            </div>
            <div className="mx-4 md:w-2/3 md:mx-auto mb-16 lg:mx-0 lg:mb-0 lg:w-80 flex flex-col items-center">
              <i className="fas fa-search-dollar text-gray-600"></i>

              <h2 className="text-xl lg:text-2xl mt-3 mb-6 font-semibold text-gray-900">
                Digital Marketing & SEO{" "}
              </h2>
              {/* <p className="text-center text-gray-500">I can help build your digital marketing strategy to automate your campaigns and get the most from your online marketing expenditure.
            A few of the tools that I use to our products such as Google ads, Mail-chimp, Twilio, and Zapier
            I will provide you with keyword/competitor research, campaign creation, ad copy, and set up weekly reports.
            </p> */}
            </div>

            <div className="mx-4 md:w-2/3 md:mx-auto mb-16 lg:mx-0 lg:mb-0 lg:w-80 flex flex-col items-center">
              <i className="fas fa-pencil-alt text-gray-600"></i>

              <h2 className="text-xl lg:text-2xl mt-3 mb-6 font-semibold  text-gray-900">
                E-Commerce{" "}
              </h2>
              {/* <p className="text-center text-gray-500">I can build your full-stack e-commerce website from scratch using MERN React, MongoDB, Node.js, and Express or Wordpress. Having an excellent google rating means more people will see your impressive product or awesome blog post. I can help you with your SEO strategy so the right people find your website and generate more quality leads.
            </p> */}
            </div>
          </div>
        </section>
      </div>
    );
}

export default Services