import React from "react";
import Growth from "./Growth";
import Showcase from "./Showcase";
import Services from "./Services";
import Testimonials from "./Testimonials";
import { Link } from 'react-router-dom';
import Button from "./Button";
import { Typewriter }  from 'react-simple-typewriter';


const Landing = () => {


  
    return (
      <div>
        <section className="relative lg:h-screen">
          <Button />
          <div className=" landing-bg lg:flex justify-center items-center h-full pt-24  pb-16 lg:pb-0">
            <div className="mx-4 mt-8 lg:mt-0 lg:ml-16">
              <span className="block uppercase text-sm lg:text-base lg:font-semibold mb-2 lg:mb-4 text-white ">
                I build websites that help
              </span>

              <h1 className=" text-5xl lg:text-7xl uppercase text-white font-medium mb-4 lg:mb-8">
                <Typewriter
                  loop
                  cursor
                  cursorStyle="_"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                  words={[
                    "Fishing Guides",
                    "Charters",
                    "Tackle Shops",
                    " Adventurers",
                  ]}
                />

                <span className="block font-bold ">
                  get more{" "}
                  <p className="bg-white text-gray-600"> trips Booked </p>{" "}
                </span>
              </h1>

              <div className="inline-block uppercase text-sm text-center font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300">
                <Link to="/report"> Free Website Report</Link>{" "}
              </div>
            </div>
          </div>
        </section>

        <Services />

        <Showcase />

        <Testimonials />

        <Growth />
      </div>
    );
}

export default Landing
