import React from 'react';

export const Showcase = () => {
    return (
      <div>
        <section className="pt-24 lg:pt-40 pb-32 lg:pb-52">
          <h2 className="text-center text-3xl lg:text-4xl font-semibold uppercase text-white mb-20 lg:mb-24">
            My Work Experience
          </h2> 


          <div className="flex flex-col items-center my-24 lg:my-40 mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex flex-row-reverse items-center justify-center gap-x-14">
            <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-white">
                  MERN stack eCommerce platform
                </h2>
                <p className="leading-relaxed my-6 text-white">
                  {" "}
                  This is a full stack eCommerce platform developed using MERN stack. I've refactored the code to use a more practical state management workflow with Redux toolkit, and Typescript.{" "}
                </p>
                <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="https://mern-stack-ecommerce-ylhi.onrender.com

                  "
                >
                  View Project
                </a>
              </div>

              <div className="relative h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tl-10xl lg:rounded-br-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-yellow-50"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("./img/ecommerce.png").default}
                  alt="Wordpress, PHP, Javascript,Front end, Back end, website development"
                />
              </div>
            </div>
          </div>



            <div className="flex flex-col items-center mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex items-center justify-center gap-x-14">
            <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-white">
                  Northern BC Outfitters
                </h2>
                <p className="leading-relaxed my-6 text-white">
                  {" "}
                  Developed a custom wordpress website for a outdoor guiding company{" "}
                </p>
                <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="https://northernbcoutfitters.ca/"
                >
                  View Project
                </a>
              </div>

              <div className="relative h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tl-10xl lg:rounded-br-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-yellow-50"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("./img/nbco.png").default}
                  alt="NoSQL, Javascript, HTML5, CSS3, Express, Node.js"
                />
              </div>


            </div>
          </div>

          <div className="flex flex-col items-center my-24 lg:my-40 mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex flex-row-reverse items-center justify-center gap-x-14">
            <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-white">
                  Soho Townhomes Complex
                </h2>
                <p className="leading-relaxed my-6 text-white">
                  {" "}
                Developed a SaaS application for a real estate company. This website is designed to drive more targeted leads to our customer. Tech used: Wordpress, MySQL, AWS, Mailchimp, Twilio, IDX plugin   {" "}
                </p>
                <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="https://sohotownhomes.ca/contact"
                  rel="nofollow"
                >
                  View Project
                </a>
              </div>

              <div className="relative h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tl-10xl lg:rounded-br-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-yellow-50"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("./img/sohotownhomes.png").default}
                  alt="Wordpress, PHP, Javascript,Front end, Back end, website development"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex items-center justify-center gap-x-14">

            <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-white">
                  MyShop, MERN stack E-commerce
                </h2>
                <p className="leading-relaxed my-6 text-white">
                  {" "}
                 This is a full stack eCommerce platform developed using MERN stack. Used as an ecommerce platform for small business ownders. I wrote the code for a full featured shopping card and shipping details. Admins have full CRUD functionality. {" "}
                </p>
                <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="https://mern-shop-0vyu.onrender.com/"
                >
                  View Project
                </a>
              </div>

              <div className="relative h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tl-10xl lg:rounded-br-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-yellow-50"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("./img/myshop.png").default}
                  alt="NoSQL, Javascript, HTML5, CSS3, Express, Node.js"
                />
              </div>


            </div>
          </div>

          <div className="flex flex-col items-center my-24 lg:my-40 mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex flex-row-reverse items-center justify-center gap-x-14">
              <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-white">
                  theridgeatbosefarms.ca
                </h2>
                <p className="leading-relaxed my-6 text-white">
                Developed a SaaS applcation for a real estate company designed to drive targeted leads through marketing funnels Tech used: Wordpress, MySQL, AWS, Mailchimp, Twilio, IDX plugin 
                </p>
                <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tl-3xl rounded-br-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="https://www.theridgeatbosefarms.com"
                  rel="nofollow"
                >
                  View Project
                </a>
              </div>

              <div className="relative h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tr-10xl lg:rounded-bl-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-yellow-50"></div>
                <img
                  className="h-full w-full object-cover"
                  src={require("./img/theridge_web.png").default}
                  alt="Wordpress, PHP, Javascript website development"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center mx-4 md:w-2/3 md:mx-auto lg:w-full lg:mx-0">
            <div className="lg:flex items-center justify-center gap-x-14">
              <div className="lg:w-1/4">
                <h2 className="inline-block text-xl font-semibold uppercase text-white">
                  Getting Spooled Youtube
                </h2>
                <p className="leading-relaxed my-6 text-white">
                  Getting spooled needed branding & design for social media.
                  Tech used: Adobe Creative Suite, Photoshop, Illustrator,
                  Graphic Design.
                </p>
                <a
                  className="inline-block uppercase text-center text-sm font-bold px-5 py-3 lg:px-10 lg:py-4 rounded-tr-3xl rounded-bl-3xl bg-gray-900 text-yellow-50 hover:bg-gray-700 focus:ring-2 focus:ring-yellow-300"
                  href="google.ca"
                  rel="nofollow"
                >
                  View Project
                </a>
              </div>

              <div className="relative h-80 lg:w-80 mt-8 lg:mt-0 -mx-4 lg:mx-0 lg:rounded-tl-10xl lg:rounded-br-10xl overflow-hidden">
                <div className="absolute top-0 right-0 left-0 bottom-0 opacity-30 bg-yellow-50"></div>
                <img
                  className="h-full w-full object-cover"
                  src={
                    require("./img/getting-spooled-fishing-canvas.png").default
                  }
                  alt="Graphic and branding design"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
}

export default Showcase